@import url('http://fonts.googleapis.com/css?family=Roboto|Architects+Daughter');
@import "bower_components/font-awesome/scss/font-awesome.scss";

@import "settings";
@import "mixins";
@import "customizer";
@import "partials/social-links";
@import "partials/sequence-theme-gallery";
@import "partials/sequence-theme-banner";

@import "bower_components/foundation/scss/foundation.scss";

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  height: auto !important;
  text-rendering: optimizeLegibility;
}

/******************************************/
/*    Navigation                          */
/******************************************/

@media #{$large-up} {
  .tab-bar {
    display: none;
  }
}

#sidebar {
  background: scale-color($earth, $lightness: -20%);
  display: none;
  height: 100%;
  height: 100vh;
  max-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1001;

  @media #{$large-up} {
    display: block;
  }

  header {
    h1 {
      font-family: $font-family-serif;
      font-weight: $font-weight-normal;
      letter-spacing: 2px;
      line-height: 0.85;
      text-align: center;
      margin: 0;

      @media #{$large-up} {
        font-size: $h1-font-size;
      }

      a {
        background-color: $primary-color;
        border-right: 1px solid scale-color($primary-color, $lightness: -10%);
        color: scale-color($primary-color, $lightness: 90%);
        text-shadow: 2px 2px 0px scale-color($primary-color, $lightness: -10%);
        text-decoration: none;
        display: block;
        padding: rem-calc(40 20);
        width: 100%;
        height: 250px;

        &:hover {
          background-color: scale-color($primary-color, $lightness: -10%);
          color: $white;
          text-shadow: 2px 2px 0px scale-color($primary-color, $lightness: -20%);
        }
      }
    }
  }
}

#menu {
  ul.side-nav {
    margin: 2em 0;
  }
}

#menu,
#menu__off-canvas {
  ul.side-nav {
    li {
      border-bottom: 1px solid $earth;
      font-weight: $font-weight-normal !important;

      a {
        color: scale-color($primary-color, $lightness: 40%) !important;
        text-decoration: none !important;

        &:hover {
          color: scale-color($primary-color, $lightness: 80%) !important;
        }

        &.active {
          color: $secondary-color !important;
        }
      }

      i {
        color: scale-color($earth, $lightness: 40%);
        text-shadow: none;
      }

      label {
        padding: .625rem 2rem;
      }

      &:last-of-type {
        border: none;
      }

      &.has-submenu {
        ul {
          height: 100%;
          height: 100vh;
        }
      }
    }

    dl.side-sub-nav {
      dt, dd {
        font-weight: $font-weight-normal !important;
        margin: 0;
        border-bottom: 1px solid scale-color($earth, $lightness: -20%);

        a {
          background-color: scale-color($earth, $lightness: -40%);
          border-bottom: none;
          color: scale-color($primary-color, $lightness: 60%);
          display: block;

          &:hover {
            color: scale-color($primary-color, $lightness: 80%) !important;
          }

          &.active {
            color: $secondary-color !important;
          }
        }
      }

      dt:first-of-type { border-top: 1px solid scale-color($earth, $lightness: -20%); }

      dd {
        padding-left: 0.5rem;
        background-color: scale-color($earth, $lightness: -25%);

        a {
          background-color: scale-color($earth, $lightness: -25%);
          color: scale-color($slate-light, $lightness: -15%) !important;
          display: block;

          &:hover {
            background-color: scale-color($earth, $lightness: -25%);
            color: $slate-light !important;
          }

          &.active {
            color: scale-color($secondary-color, $lightness: 10%) !important;
          }
        }

        &:hover {
          background-color: scale-color($earth, $lightness: -25%);
        }

        &:first-of-type {
          border-top: 1px solid scale-color($earth, $lightness: -30%);
        }
      }
    }
  }
}

/******************************************/
/*    Content                             */
/******************************************/

div#wrapper {
  background-color: scale-color($slate-light, $lightness: 80%);

  #banner {
    background-color: $slate-light;
    border-bottom: 1px solid $smoke;
    height: 150px;

    @media #{$medium-up} {
      height: 250px;
    }
  }

  #content {
    padding: 7.5% 2.5%;

    @media #{$medium-up} {
      padding: rem-calc(60) rem-calc(30);
      margin: 0 auto;
    }

    main {
      @media #{$large-up} {
        max-width: 960px !important;
      }

      #subheader {
        border-bottom: 1px solid scale-color($slate-dark, $lightness: 60%);
        padding: 0 0 2rem;
        margin-bottom: 2em;

        h2 {
          color: $secondary-color;
          font-family: $font-family-serif;
          font-size: $h2-font-size;
          font-weight: $font-weight-normal;
          display: inline-block;
          margin: 0;
        }

        .subheader__button {
          .button {
            display: inline-block;
            margin: 0 !important;
          }
        }
      }

      #page {
        article, section {
          padding: 1em 0;
        }

        h2, h3, h4 {
          a {
            color: $header-font-color;
            text-decoration: none;
          }

          a:hover {
            color: $header-font-color;
            text-decoration: none;
          }
        }

        p {
          font-size: 1rem;

          @media #{$large-up} {
            font-size: 1.1rem;
          }

           a {
             text-doecoratrion: none;

             &:hover {
               text-doecoratrion: underline !important;
             }
           }
        }

        figure {
          margin: 0 !important;

          img {
            border: 5px solid scale-color($slate-dark, $lightness: 60%);
          }

          figcaption {
            color: $steel;
            font-size: rem-calc(14);
            font-style: italic;
            padding: 0.5rem 0;
            text-align: center;
          }
        }
      }
    }
  }

  #footer {
    background-color: scale-color($slate-dark, $lightness: 80%);
    border-top: 1px solid scale-color($slate-dark, $lightness: 60%);
    font-size: 0.85rem;
    padding: rem-calc(35 50 30 50);

    @media #{$medium-up} {
      font-size: 1rem;
    }

    a {
      color: scale-color($slate-dark, $lightness: -20%);

      &:hover {
        color: scale-color($slate-dark, $lightness: -40%);
      }
    }

    span {
      color: $steel;

      i {
        color: $steel ;
      }
    }

    ul {
      display: inline-block !important;
      margin: 0;
      font-size: 0.85rem;

      @media #{$medium-up} {
        font-size: 1rem;
      }

      li:first-of-type {
        margin-left: 0;
      }
    }
  }
}

/******************************************/
/*    Pages                               */
/******************************************/

.home {
  .description-text{
    p {
      font-size: 1rem;

      @media #{$medium-only} {
        font-size: 1.25rem !important;
      }

      @media #{$large-up} {
        font-size: 1.5rem !important;
      }
    }
  }
}

.inspiration {
  .collection {
    display: block;
    margin: 1em;

    img {
      border: 5px solid scale-color($slate-dark, $lightness: 80%);
      transition : border 100ms ease-out;
      min-width: 100%;
    }

    h3 {
      font-family: $font-family-serif;
      font-weight: $font-weight-normal;
      text-align: center;
      width: 100%;
    }

    &:hover img {
      border: 5px solid $slate-dark;
    }

    &:hover h3 a {
      text-decoration: none;
    }
  }
}

.portfolio {
  h3.portfolio-title {
    font-family: $font-family-serif;
    font-weight: $font-weight-normal;
    margin: 0 0 1em;
  }
}

.events, .event {
  article {
    padding: rem-calc(0.5);

    h3.event-title {
      font-family: $font-family-serif;
      font-weight: $font-weight-normal;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.event {
  figure::before {
    margin: 0 !important;
  }
}

.contact {
  section#map_canvas {
    display: block;
    margin: 0 0 2em;
  }

  textarea {
    min-height: 15rem;
  }

  input.honeypot {
    display: none;
  }
}

/******************************************/
/*    Reusuables                          */
/******************************************/

#post-nav {
  border-top: 1px solid scale-color($slate-dark, $lightness: 60%);
  padding: 2rem 0 0;
  margin-top: 2em;
  font-size: 0.85rem;

  @media #{$medium-up} {
    font-size: 1rem;
  }

  .post-nav__previous,
  .post-nav__next {
    i {
      line-height: 1.5rem !important;
    }
  }
}

#social {
  padding: $side-nav-link-padding;
  @include social-links;
}

#share {
  margin-top: 2em;
}
