.seq__banner {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;

  .seq-screen,
  .seq-canvas,
  .seq-canvas > * {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    width: 100%
  }

  .seq-canvas {
    font-size: 0;

    > * {
      position: absolute;
      zoom: 1;
      vertical-align: top;
      width: 100%;
      height: 100%;
      white-space: normal;
      text-align: center;
    }

    > *:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  .seq-preloader {
    background-color: $smoke;
  }

  .seq-content {
    display: inline-block;
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    img.seq-image {
      width: 100%;
      min-width: 100%;
      opacity: 0;
      transition: opacity 1000ms ease-in-out;
    }

    img.seq-single {
      opacity: 1;
    }
  }

  &.seq-active {
    visibility: visible;
    opacity: 1;

    .seq-preloader {
      opacity: 1;
      visibility: visible;
    }

    .seq-preloader.seq-preloaded {
      opacity: 0;
      visibility: hidden;
    }

    .seq-preloader .seq-preload-circle {
      fill: $primary-color;
    }
  }

  .seq-in {
    /* Animate in positions for content */
    img.seq-image {
      opacity: 1;
    }
  }

  .seq-out {
    /* Animate out positions for content */
    img.seq-image {
      opacity: 1;
    }
  }
}
