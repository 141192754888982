.no-pad {
  padding: 0 !important;
}

a {
  transition: 100ms ease-in-out !important;
}

a.button {
  text-decoration: none !important;
}

#newsletter {
  border-color: scale-color($slate-light, $lightness: -10%);

  button {
    margin-bottom: 0;
  }
}

.panel h2,
.panel h3,
.panel h4 {
  font-family: $font-family-serif;
  font-weight: $font-weight-normal;
}

span.label {
  margin-left: 0.5rem;
  top: -2px;
  background-color: $smoke;
  color: $oil;
}

i {
  color: $slate-dark;
  padding: 0 0.25em;
  line-height: 1.4;

  &.left {
    padding: 0 .5em 0 0;
  }

  &.right {
    padding: 0 0 0 .5em;
  }
}

.button,
button {
  i {
    color: $white;
    font-size: rem-calc(24);
    line-height: rem-calc(24);
  }

  &.tiny i {
    font-size: rem-calc(16);
    line-height: rem-calc(16);
  }

  &.small i {
    font-size: rem-calc(20);
    line-height: rem-calc(20);
  }

  &.large i {
    font-size: rem-calc(36);
    line-height: rem-calc(36);
  }
}

ul, dl {
  line-height: 1.85;
}

.tab-bar h1 {
  font-size: rem-calc(24);

  a {
    color: scale-color($primary-color, $lightness: 90%);
    text-shadow: 2px 2px 0px scale-color($primary-color, $lightness: -10%);
    text-decoration: none;

    &:hover {
      color: white;
    }
  }
}
