.seq__gallery {
  position: relative;

  /* Dimensions */
  @include aspect-ratio(3, 2);
  width: 100%;
  max-width: 100%;
  height: 100%;

  /* Center the Sequence container on the page */
  margin: 0 auto;
  padding: 0;

  /* Some basic styles */

  .seq-pagination,
  .seq-screen,
  .seq-canvas,
  .seq-canvas > * {
    /* Reset the canvas and steps for better browser consistency */
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .seq-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .seq-preloader {
    background: $vapor;
  }

  .seq-canvas {
    /* Make the canvas the same dimensions as the container and prevent lines
     * from wrapping so each step can sit side-by-side */
    position: absolute;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    /* Reset the font-size to remove 4px from :before and .title */
    font-size: 0;


    > * {
      /* Make the steps the same size as the container and sit side-by-side */
      display: inline-block;
      zoom: 1;
      vertical-align: top;
      width: 100%;
      height: 100%;
      white-space: normal;
      text-align: left;
      transform: translateZ(0);
    }

    > *:before {
      /* Vertically align .seq-content */
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  .seq-content {
    /* Default styles for content */
    display: inline-block;
    /* Reset the font-size from 0 back to 16 */
    font-size: 16px;

    img {
      border: none !important;
      min-width: 100%;
      opacity: 0;
      border: 5px solid scale-color($slate-dark, $lightness: 60%) !important;
      transition: opacity 300ms linear;
    }

    .seq-caption {
      position: absolute;
      left: 15px;
      bottom: 10px;

      figcaption {
        background-color: transparentize($smoke, 0.4);
        color: $oil !important;
        display: inline-block;
        padding: 0.25em 1em !important;
        opacity: 0;
        transition: opacity 300ms linear;
      }
    }
  }

  &.seq-active {
    /* when JS is enabled */

    /* Hide anything that goes beyond the boundaries of the Sequence container */
    overflow: hidden;

    .seq-preloader,
    .seq-pagination {
      visibility: visible;
    }

    .seq-preloader.seq-preloaded {
      visibility: hidden;
    }
  }

  .seq-in {
    /* Animate in positions for content */
    .seq-content {
      img {
        opacity: 1;
      }

      .seq-caption {
        figcaption {
          opacity: 1;
        }
      }
    }
  }

  .seq-out {
    /* Animate out positions for content */
    .seq-content {
      img {
        opacity: 0;
        transition-duration: 50ms !important;
      }

      .seq-caption {
        figcaption {
          opacity: 0;
          transition-duration: 50ms !important;
        }
      }
    }
  }

  @media only screen and (min-width: 600px) {

    /* Make the container 16:9 but no bigger than 90% of the screen height */
    @include aspect-ratio(3, 2);
    height: 100%;

    .seq-pagination > * {
      width: 20px;
      height: 20px;
    }
  }
}

.seq-pagination {
  display: block;
  z-index: 10;
  width: 100%;
  border: none;
  text-align: center;
  margin-left: 0;

  a {
    display: block;
    width: 100%;
    height: 100%;
    border: none !important;
  }

  .seq-current {
    background-color: $primary-color;
    border: 3px solid $primary-color;

    &:focus,
    &:hover {
      background-color: $primary-color;
    }
  }

  > * {
    border-radius: 100%;
    margin: 0 5px;
    padding: 0;
    display: inline-block;
    zoom: 1;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background: $white;
    border: 3px solid scale-color($primary-color, $lightness: 20%);
    transition: back-color, border-color 100ms ease-in-out;

    &:focus,
    &:hover {
      background-color: $white;
      border: 3px solid $primary-color;
    }

    /* Image replacement */
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
  }
}

.seq-preloader .seq-preload-circle {
  fill: $primary-color;
}
