@mixin social-links {
  a {
    font: normal normal normal FontAwesome;
    font-size: rem-calc(24);

    &.twitter i {
      color: lighten(#00aced, 10%);
      &:hover {
        color: #00aced;
      }
    }

    &.facebook i {
      color: lighten(#3b5998, 10%);
      &:hover {
        color: #3b5998;
      }
    }

    &.linkedin i {
      color: lighten(#007bb6, 10%);
      &:hover {
        color: #007bb6;
      }
    }

    &.googleplus i {
      color: lighten(#dd4b39, 10%);
      &:hover {
        color:#dd4b39;
      }
    }

    &.youtube i {
      color: lighten(#bb0000, 10%);
      &:hover {
        color: #bb0000;
      }
    }

    &.tumblr i {
      color: lighten(#32506d, 10%);
      &:hover {
        color: #32506d;
      }
    }

    &.instagram i {
      color: lighten(#517fa4, 10%);
      &:hover {
        color: #517fa4;
      }
    }

    &.pinterest i {
      color: lighten(#cb2027, 10%);
      &:hover {
        color: #cb2027;
      }
    }
  }
}
